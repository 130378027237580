<template>
  <ZCircleButton
    class="zclose"
    aria-label="Close"
  >
    <fa :icon="['far', 'xmark']" />
  </ZCircleButton>
</template>

<script setup lang="ts">

</script>
